import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PageProps, graphql } from "gatsby";

import PageShell from "../components/PageShell";
import React from "react";
import classnames from "classnames";
import { useDarkMode } from "../components/DarkModeToggle";

function Thumbnail({ photo }: { photo: any }) {
  const { isDarkMode } = useDarkMode();
  const image = getImage(photo.localImage);

  if (!image) {
    return <p className="text-red-500">could not load image :/</p>;
  }

  return (
    <div className="grid-cols-1 md:grid-cols-3 grid gap-2">
      <div className={classnames("md:col-span-2 justify-self-end")}>
        <a href={photo.url} target="_blank">
          <div
            className={classnames(
              "group rounded-sm ring-4 ring-black overflow-hidden relative",
              {
                "ring-purple-100": isDarkMode,
              }
            )}
          >
            <GatsbyImage image={image} alt={photo.name} />
            <div className="gothic opacity-0 group-hover:opacity-100 transition duration-100 ease-in-out p-3 absolute bottom-0 left-0 bg-gray-300 bg-opacity-80 w-full flex justify-between">
              <div className="text-sm  text-gray-800">Click to expand</div>
            </div>
          </div>
        </a>
      </div>
      <div
        className={classnames("md:pl-8 pt-2 md:pt-6 font-sans", {
          "text-white": isDarkMode,
          "text-gray-800": !isDarkMode,
        })}
      >
        <p className="font-extrabold tracking-wider ">{photo.name}</p>
        <p className="mt-1 text-sm">{photo.details}</p>
      </div>
    </div>
  );
}

function Abstract() {
  const { isDarkMode } = useDarkMode();

  return (
    <div
      className={classnames("font-sans pb-4", {
        "text-white": isDarkMode,
        "text-gray-800": !isDarkMode,
      })}
    >
      Here a some photos I've taken around San Francisco. Enjoy!
    </div>
  );
}

export default function IndexRoute(props: PageProps) {
  return (
    <>
      <PageShell>
        <Abstract />
        <div className="my-8 grid grid-cols-1 gap-20">
          {/* @ts-ignore */}
          {props.data.allPhotosYaml.edges
            .map(
              ({
                // @ts-ignore
                node,
              }) => node
            )
            .map(
              (
                // @ts-ignore
                photo
              ) => {
                return <Thumbnail photo={photo} key={photo.name} />;
              }
            )}
        </div>
      </PageShell>
    </>
  );
}

export const pageQuery = graphql`
  query {
    allPhotosYaml {
      edges {
        node {
          name
          url
          details
          localImage {
            childImageSharp {
              gatsbyImageData(
                height: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`;
