import React, { useState } from "react";

import { DarkModeContext } from "./DarkModeToggle";
import Header from "./Header";

export default function PageShell({ children }: { children: React.ReactNode }) {
  const initialDarkMode =
    typeof window !== "undefined"
      ? window.localStorage.getItem("darkMode") === "true"
      : false;
  const [isDarkMode, setDarkMode] = useState(initialDarkMode);

  return (
    <DarkModeContext.Provider
      value={{
        isDarkMode,
        setDarkMode,
      }}
    >
      <div className="container mx-auto px-16 pt-8">
        <Header />
        <div className="mt-8">{children}</div>
      </div>
    </DarkModeContext.Provider>
  );
}
