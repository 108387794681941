import DarkModeToggle, { useDarkMode } from "./DarkModeToggle";

import React from "react";
import classnames from "classnames";

function LinkPill({ href, label }: { href: string; label: string }) {
  const { isDarkMode } = useDarkMode();

  return (
    <a
      className={classnames(
        "px-3 py-2 border-2 rounded-full text-xs font-semibold font-sans",
        {
          "border-gray-500 text-gray-200 hover:bg-gray-800": isDarkMode,
          "hover:bg-yellow-200 border-transparent": !isDarkMode,
        }
      )}
      href={href}
      target="_blank"
    >
      {label}
    </a>
  );
}

export default function Header() {
  const { isDarkMode } = useDarkMode();

  return (
    <div>
      <div className="flex justify-between">
        <div className="pb-4">
          <h1
            className={classnames("gowun text-3xl font-semibold", {
              "text-gray-100": isDarkMode,
            })}
          >
            Mark's Photo Gallery
          </h1>
        </div>
        <div>
          <DarkModeToggle />
        </div>
      </div>
      <div className="flex space-x-3">
        <LinkPill href="https://mark.larah.me/" label="Home" />
        <LinkPill href="https://blog.larah.me/" label="Blog" />
        <LinkPill href="https://github.com/magicmark/recipes" label="Recipes" />
        <LinkPill href="https://twitter.com/mark_larah" label="Twitter" />
      </div>
    </div>
  );
}
