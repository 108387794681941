import React, { createContext, useContext, useEffect } from "react";

import classnames from "classnames";

export const DarkModeContext = createContext({
  isDarkMode: false,
  setDarkMode: (isDarkMode: boolean) => {},
});

export function useDarkMode() {
  const { isDarkMode, setDarkMode } = useContext(DarkModeContext);
  return { isDarkMode, setDarkMode };
}

const moon = (
  <svg
    className="w-5 h-5"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
  </svg>
);

const sun = (
  <svg
    className="w-5 h-5"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
      clipRule="evenodd"
    />
  </svg>
);

export default function DarkModeToggle() {
  const { isDarkMode, setDarkMode } = useContext(DarkModeContext);

  useEffect(() => {
    window.localStorage.setItem("darkMode", JSON.stringify(isDarkMode));
    document.documentElement.setAttribute(
      "data-theme",
      isDarkMode ? "dark" : "light"
    );
  });

  return (
    <button
      title="toggle dark mode"
      className={classnames(
        "rounded-lg p-4 shadow-lg transition duration-100",
        {
          "bg-gray-800  text-gray-300 hover:text-yellow-300": isDarkMode,
          "bg-yellow-100 shadow-none text-gray-600 hover:text-black": !isDarkMode,
        }
      )}
      onClick={() => {
        setDarkMode(!isDarkMode);
      }}
    >
      {isDarkMode ? sun : moon}
    </button>
  );
}
